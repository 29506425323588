<template>
  <div class="video_container">
    <video
      class="video"
      controls=""
      preload="auto"
      width="1556"
      height="968"
      src="https://0.rc.xiniu.com/g3/M00/1B/32/CgAH6F34SoyAb8ijCvvGBBnRV24799.mp4"
      poster="@/assets/images/video_cover.png"
    ></video>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.video_container {
  margin-top: 149px;
  .video {
    margin: 0 auto;
    margin-left: 181px;
    object-fit: cover;
  }
}
</style>
