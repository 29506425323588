<template>
  <div class="video_list_container">
    <div class="video_list_title">更多视频</div>
    <div class="video_list">
      <div class="video_item" v-for="item in article_list" :key="item.id">
        <h3 class="title">{{ item.title }}</h3>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'video_list',
  data() {
    return {
      article_list: [
        {
          id: 1,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 2,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 3,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 4,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 5,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 6,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.video_list_container {
  padding: 119px 181px 76px;
  .video_list_title {
    font-size: 32px;
    font-weight: 400;
    color: #010343;
    margin-bottom: 15px;
  }
}

.video_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .video_item {
    width: 491px;
    height: 127px;
    background: #f7f9fd;
    padding: 15px 45px 0 17px;
    margin-bottom: 25px;
    cursor: pointer;
    &:hover .title {
      color: #0000ff;
    }
    .title {
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 15px;
    }
    .content {
      font-size: 19px;
      font-weight: 300;
      color: #010343;
      line-height: 27px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
