<template>
  <div class="page_video_container w">
    <!-- 顶级占位符 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 视频 -->
    <Video></Video>

    <!-- 更多视频列表 -->
    <VideoList></VideoList>
  </div>
</template>

<script>
import Video from './components/video.vue'
import VideoList from './components/video_list.vue'
export default {
  name: 'PageVideo',
  components: {
    Video,
    VideoList
  },
  data() {
    return {
      title: '泉邦案例',
      complement: '饮水思泉  立业兴邦'
    }
  }
}
</script>

<style lang="less" scoped></style>
